"use client";

export default function GlobalError({ error, reset }) {
  return (
    <html>
      <body>
        <div className="my-[180px] mb-14 flex items-center justify-center">
          <div className="w-[60%] rounded-lg border border-secondary-border-color bg-white px-4 py-4 shadow-md lg:px-9 lg:py-8">
            <div className="text-center">
              <h2 className="text-2xl font-medium text-color-black">
                Something went wrong!
              </h2>
              <p>{error.message}</p>
              <button
                className="mt-3 cursor-pointer whitespace-nowrap rounded border border-solid border-primary-border-color bg-primary-color p-2 px-3 py-2 text-xs font-medium text-white "
                onClick={() => reset()}
              >
                Try again
              </button>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
